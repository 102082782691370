
import { Context } from '@nuxt/types'
import { Component, mixins } from 'nuxt-property-decorator'

// Import State & Types
import cloudSamlQuery from '~/apollo/queries/auth/cloudSaml.gql'

import AuthMixin from '~/mixins/authMixin'
import MetaMixin from '~/mixins/metaMixin'

import { routerVcsMap } from '~/plugins/helpers/provider'
import { AuthActionTypes } from '~/store/account/auth'

import { GraphqlQueryResponse } from '~/types/apollo-graphql-types'
import { AuthUrl } from '~/types/types'

@Component({
  middleware: [
    'redirectToHome',
    async function ({ redirect, route, $config, $providerMetaMap, store }: Context): Promise<void> {
      const { provider } = route.query
      await store.dispatch(`account/auth/${AuthActionTypes.FETCH_AUTH_URLS}`)
      const authUrls = store.state.account.auth.authUrls as Array<AuthUrl>

      const validProviders = [
        ...($config.githubEnabled ? [routerVcsMap.gh] : []),
        ...($config.githubServerEnabled ? [routerVcsMap.ghe] : []),
        ...($config.gitlabEnabled ? [routerVcsMap.gl] : []),
        ...($config.bitbucketEnabled ? [routerVcsMap.bb] : []),
        ...($config.adsEnabled ? [routerVcsMap.ads] : []),
        ...($config.gsrEnabled ? [routerVcsMap.gsr] : []),
        ...($config.bitbucketDatacenterEnabled ? [routerVcsMap.bbd] : [])
      ]

      if (
        provider &&
        typeof provider === 'string' &&
        validProviders.includes(provider as routerVcsMap)
      ) {
        // Convert provider value from query param to a valid `VCSProviderChoices` entry
        const vcsProviderChoice = $providerMetaMap[provider].value

        const authUrl = authUrls.find((authUrl) => authUrl.provider === vcsProviderChoice)?.url

        // 307 is temporary redirect
        authUrl && redirect(307, authUrl)
      }
    }
  ]
})
export default class SignIn extends mixins(AuthMixin, MetaMixin) {
  metaTitle = 'Login - DeepSource'
  setCanonical = true
  samlLogin = false
  samlLoginError = false
  samlLoginLoading = false
  samlEmail = ''

  /**
   * Reset input data and UI state from login via SAML to normal login
   */
  resetSamlLogin() {
    this.samlLogin = false
    this.samlLoginError = false
    this.samlEmail = ''
  }

  /**
   * Perform next action on "Continue with SSO" button click
   *
   * - If the UI is in SAML login mode, run the SAML login verification method {@link verifySamlLogin}
   * - If the UI is in normal login mode, toggle on the SAML login mode
   */
  nextSamlAction() {
    if (this.samlLogin && this.samlEmail) {
      this.verifySamlLogin()
      return
    }
    this.samlLogin = true
  }

  /**
   * Verify user's email for SAML login. If valid, redirect user to the SAML login endpoint.
   */
  async verifySamlLogin() {
    this.samlLoginLoading = true
    try {
      const samlLoginResponse = (await this.$fetchGraphqlData(
        cloudSamlQuery,
        { email: this.samlEmail },
        true
      )) as GraphqlQueryResponse

      const samlAuthUrl = samlLoginResponse.data.saml2LoginUrl

      if (samlAuthUrl) {
        location.href = samlAuthUrl
      } else {
        this.samlLoginError = true
        this.samlLoginLoading = false
      }
    } catch (e) {
      const loginError = e as Error
      this.$logErrorAndToast(
        loginError,
        loginError.message.replace('GraphQL error: ', '') as `${string}.`
      )
      this.samlLoginLoading = false
    }
  }
}
